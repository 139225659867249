import React, {useContext, useEffect, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css'; // Optional: For styling
import FlightDetailsModal from './FlightDetailsModal';
import 'chart.js/auto';
import CountryCitySelector from "./CountryCitySelector";
import {BrowserRouter as Router, Link} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Home from './components/Home';
import {onAuthStateChanged} from "firebase/auth";
import {auth} from './components/firebase';
import {signOut} from "firebase/auth";
import Dashboard from "./components/Dashboard";
import NotFound from './components/NotFound';

function App() {
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL || "http://localhost:5000";

    const [user, setUser] = useState(null);

    // TODO move this to a separate component (e.g. AuthContext.js or Login.js by passing user param or Dashboard.js)
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                setUser(user);
                // ...
                console.log("uid:", uid)
            } else {
                signOut(auth).then(() => {
                    // Sign-out successful.
                    // TODO implement  signout properly
                    console.log("Signed out successfully")
                }).catch((error) => {
                    // An error happened.
                    console.log("Error signing out", error.message)
                });
            }
        });
    }, [])

    // TODO move this to a separate component (e.g. Tile.js)
    // TODO img resource should be dynamic and put in src/assets folder
    const Tile = ({item, onButtonClick}) => {
        return (
            <div className={"grid-item"}>
                <img className={"item-image"}
                     src={`${process.env.PUBLIC_URL}/images/${item.cityTo}.webp`}
                     onError={
                         (e) => {
                             e.target.src = `${process.env.PUBLIC_URL}/images/${item.countryTo}.webp`;
                             e.target.onerror = () => e.target.src = `${process.env.PUBLIC_URL}/images/default_travel_pic.webp`;
                         }
                     }
                     alt={item.title}
                    // style={{width: '150px', height: '150px', objectFit: 'cover'}}
                />
                <br/>
                <p>{item.cityTo}<br/><b>{item.countryTo}</b><br/><br/>
                    <s>€{displayPriceInEuros(item.previous_fare)}</s> <br/>
                    <span
                        className="price-new">€{displayPriceInEuros(item.fare)}</span> ({displayPriceInEuros(item.percentage_change)}%) <br/>
                </p>
                <br/>
                {/*<br/><b>Discount:{displayPriceInEuros(item.percentage_change)}%</b>*/}

                <button onClick={() => onButtonClick(item)}>
                    View Details
                </button>

            </div>
        );
    };

    function displayPriceInEuros(value) {
        // Format the value as Euro currency with two decimal places
        return new Intl.NumberFormat('de-DE', {
            // style: 'currency',
            // currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    }

    const FLIGHTS_PER_PAGE = 12;
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [showFlightDetailsModal, setShowFlightDetailsModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);  // For datepicker
    const [tileData, setTileData] = useState([]);
    const [allFlightData, setAllFlightData] = useState([]);
    const [selectedTileData, setSelectedTileData] = useState(null);
    const [priceData, setPriceData] = useState([]);
    const [differentParams, setDifferentParams] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const filteredItems = tileData.filter(flight =>
        flight.cityTo.toLowerCase().includes(filterText.toLowerCase()) ||
        flight.countryTo.toLowerCase().includes(filterText.toLowerCase())
    );

    const totalPages = Math.ceil(filteredItems.length / FLIGHTS_PER_PAGE);
    const paginatedItems = filteredItems.slice(
        (currentPage - 1) * FLIGHTS_PER_PAGE,
        currentPage * FLIGHTS_PER_PAGE
    );
    const handleFilterChange = (e) => {
        setFilterText(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleRedirect = () => {
        console.log('Redirecting to Kiwi.com')
        console.log("Selected Flight Deep Link: ", selectedTileData.deep_link)
        const kiwiLink = selectedTileData.deep_link;
        window.open(kiwiLink, '_blank');
    };

    const handleCloseFlightDetails = () => setShowFlightDetailsModal(false);

    // Handler for when a button is clicked inside a tile
    const handleViewDetailsButtonClick = (item) => {
        console.log('Tile clicked:', item);
        console.log('All Flights Data:', allFlightData);
        setSelectedTileData(item);
        setPriceData(allFlightData.filter(flight => flight.id === item.id));
        setShowFlightDetailsModal(true);
    };

    // Fetch results based on selected country and city
    const handleGetSearchResults = () => {
        if (selectedCountry && selectedCity && selectedDate) {
            console.log("executing handleGetSearchResults") // Debugging
            const dateString = selectedDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
            fetch(`${apiUrl}/api/results?country_from=${selectedCountry}&city_from=${selectedCity}&date=${dateString}`)
                .then(response => response.json())
                .then(data => {
                    setTileData(data)
                    let selectedIds = data.map(flight => flight.id);
                    console.log("selectedIds", selectedIds);
                    fetch(`${apiUrl}/api/details`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({selectedDate: selectedDate, flightIds: selectedIds}),
                    })
                        .then(response => response.json())
                        .then(data => {
                            console.log("flights details call data", data);
                            setAllFlightData(data)
                        })
                        // .then(data => setAllFlightData(data)
                        .catch(error => console.error('Error in getting flight details:', error));
                })
                .catch(error => console.error('Error fetching results:', error));
        }
    };

    const toggleDiv = () => setDifferentParams(!differentParams);

    useEffect(() => {
        // Fetch the user's location based on their IP address
        fetch('https://get.geojs.io/v1/ip/geo.json')
            .then(response => response.json()) // Parse the JSON from the response
            .then(data => {
                setSelectedCountry(data.country);
                const today = new Date();
                const nextWeek = new Date(today);
                nextWeek.setDate(today.getDate() + 7);
                const dateString = nextWeek.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
                fetch(`${apiUrl}/api/results?country_from=${data.country}&date=${dateString}`)
                    .then(response => response.json())
                    .then(data => {
                        setTileData(data)
                        let selectedIds = data.map(flight => flight.id);
                        console.log("selectedIds", selectedIds);
                        fetch(`${apiUrl}/api/details`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({selectedDate: dateString, flightIds: selectedIds}),
                        })
                            .then(response => response.json())
                            .then(data => {
                                console.log("flights details call data", data);
                                setAllFlightData(data)
                            })
                            // .then(data => setAllFlightData(data)
                            .catch(error => console.error('Error in getting flight details:', error));
                    })
                    .catch(error => console.error('Error fetching results:', error));
            })
            .catch(error => {
                console.error('Error fetching the location:', error);
            })
    }, [])

    // Handle country change
    const handleCountryChange = (event) => {
        const country = event.target.value;
        setSelectedCountry(country);
        setSelectedCity(""); // Reset city selection when country changes
    };

    // Handle city change
    const handleCityChange = (event) => {
        const city = event.target.value;
        setSelectedCity(city);
    };

    const handleFlightDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <h1>Latest Flight Deals</h1>
                </header>
                <nav className="navbar">
                    <div className="navbar-links">
                        <Link to="/" className="link">Home</Link>
                        <Link to="/signup" className="link">SignUp</Link>
                        <Link to="/login" className="link">Login</Link>
                    </div>
                </nav>

                <section>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/signup" element={<SignUp/>}/>
                        <Route path="/login" element={<Login/>}/>
                        {/* Catch-all route for undefined paths */}
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </section>
                <br/>
                {/*<img src={sampleImage} alt="Sample" width="500"/>*/}
                <div>
                    {selectedCountry ? <h3>Showing Deals for: {selectedCountry}</h3> : <h3>Loading...</h3>}
                </div>
                <div>
                    {user ? <Dashboard/> : <><Home/></>}

                    <br/><br/><br/>
                    {/*{differentParams && (*/}
                    <CountryCitySelector
                        selectedCountry={selectedCountry}
                        selectedCity={selectedCity}
                        selectedDate={selectedDate}
                        handleCountryChange={handleCountryChange}
                        handleCityChange={handleCityChange}
                        handleDateChange={handleFlightDateChange}
                        handleGetSearchResults={handleGetSearchResults}
                        differentParams={differentParams}
                    />
                    {/*)}*/}
                    <br/>
                </div>
                <br/><br/>


                <div className="pagination">
                    <input
                        type="text"
                        placeholder="Search flights deals..."
                        value={filterText}
                        onChange={handleFilterChange}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={index + 1 === currentPage ? 'active' : ''}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                {tileData && (
                    <div className="grid-container">
                        {paginatedItems.map((item) => (

                            <Tile
                                key={item.id}
                                item={item}
                                onButtonClick={handleViewDetailsButtonClick}
                            />

                        ))}
                    </div>
                )}

                <br/><br/><br/>

                {selectedTileData && (
                    <FlightDetailsModal
                        open={showFlightDetailsModal}
                        selectedTileData={selectedTileData}
                        priceData={priceData}
                        handleClose={handleCloseFlightDetails}
                        // handleMonitor={handleTrackFlight}
                        handleBuyTicket={() => handleRedirect()}
                    />
                )}
            </div>
        </Router>
    );
}

export default App;