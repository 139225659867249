// Dashboard.js
import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await signOut(auth);
            console.log('User logged out');
            // TODO Redirect to the login page - not working
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <div>
            <h1>Welcome to Flight Glimpse</h1>
            <p>Below is the list of flights you are monitoring</p>
            <button onClick={handleLogout}>Log Out</button>
        </div>
    );
};

export default Dashboard;