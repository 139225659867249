// Login.js
import React, {useState} from "react";
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';

// TODO make below async?
const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // Initialize Firebase Authentication and get a reference to the service
    const auth = getAuth();

    const handleLogin = async () => {
        await signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log("Signed in:", user);
            })
            .catch((error) => {
                console.error("Error signing in:", error);
            });
    };

    return (
        <div>
            <h2>Login</h2>
            <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
            <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
            <button onClick={handleLogin}>Login</button>
        </div>
    );
};

export default Login;