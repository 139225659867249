// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCAyBuDB_Ipbn0kHH4Tpqc_JIEHikmpG5s",
    authDomain: "koz-software.firebaseapp.com",
    projectId: "koz-software",
    storageBucket: "koz-software.firebasestorage.app",
    messagingSenderId: "912935791231",
    appId: "1:912935791231:web:343902ba252ee340442d4a"
    // measurementId: "G-SW5WC3M5MN"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);

// Initialize Firebase Authentication and export it
export const auth = getAuth(firebaseApp);