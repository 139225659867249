import React, {useState} from 'react';
import {TextField} from '@mui/material';
import {Line} from "react-chartjs-2";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography} from '@mui/material';

const FlightDetailsModal = ({open, selectedTileData, priceData, handleClose, handleBuyTicket}) => {

    const [flightSubscription, setFlightSubscription] = useState(false);
    const [flightSubscriptionCompleted, setFlightSubscriptionCompleted] = useState(false);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour12: false  // Use 24-hour format
        });
    };

    const options = {
        scales: {
            x: {
                type: 'category',
                title: {
                    display: true,
                    text: 'Date',
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'Price (€)',
                },
                beginAtZero: true,
            },
        },
    };

    // Prepare data for the chart
    const chartData = {
        // TODO chart only showing 2 data point per day
        labels: priceData.map(item => formatTimestamp(item.collectedAt)),
        datasets: [
            {
                label: 'Price (€)',
                data: priceData.map(item => item.fare),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderWidth: 2,
                fill: true,
            },
        ],
    };

    const [email, setEmail] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubscriptionSubmit = (event) => {
        event.preventDefault();
        setFlightSubscriptionCompleted(true);
        // Here you can handle the email submission, e.g., form validation or sending to an API
        console.log('Email submitted:', email);
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="popup-dialog-title"
            PaperProps={{
                style: {
                    borderRadius: 8, // Rounded corners
                    padding: '20px', // Padding inside the dialog
                    maxWidth: '90%', // Responsive behavior
                    width: '600px', // Adjusted width for a larger dialog
                    height: '600px', // Adjusted height to accommodate the chart
                },
            }}
        >
            <DialogTitle id="popup-dialog-title">Flight Details: {selectedTileData?.flightName}</DialogTitle>
            {/*<form onSubmit={handleSubscriptionSubmit}>*/}

                <DialogContent>
                    <Typography sx={{mt: 2}}>
                        Airline: {selectedTileData?.airlines[0]}
                    </Typography>
                    <Typography>
                        Time: {selectedTileData?.local_departure}
                    </Typography>
                    <Typography>
                        Departure: {selectedTileData?.cityFrom}
                    </Typography>
                    <Typography>
                        Arrival: {selectedTileData?.cityTo}
                    </Typography>
                    <Typography>
                        Price Changes Over Time
                        <Line data={chartData} options={options}/>
                    </Typography>
                    {/*<h2>Price Changes Over Time for {selectedTileData.cityTo}, {selectedTileData.countryTo}</h2>*/}
                    {/*{flightSubscription && (*/}
                    {/*    <TextField*/}
                    {/*        label="Email"*/}
                    {/*        type="email"*/}
                    {/*        value={email}*/}
                    {/*        onChange={handleEmailChange}*/}
                    {/*        fullWidth*/}
                    {/*        required*/}
                    {/*        multiline={false}*/}
                    {/*        margin="normal"*/}
                    {/*        variant="outlined"*/}
                    {/*    />*/}
                    {/*)}*/}
                    {/*<Button type="submit" variant="contained" color="primary">*/}
                    {/*    Submit*/}
                    {/*</Button>*/}

                    {/*{flightSubscriptionCompleted && (*/}
                    {/*    <Typography>*/}
                    {/*        You are now monitoring this flight. We will notify you of any price changes.*/}
                    {/*    </Typography>*/}
                    {/*)}*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Back
                    </Button>
                    <Button onClick={() => setFlightSubscription(true)} color="primary" variant="contained">
                        Monitor
                    </Button>
                    <Button onClick={handleBuyTicket} color="primary" variant="contained">
                        Buy
                    </Button>
                </DialogActions>
            {/*</form>*/}
        </Dialog>
    );
};

export default FlightDetailsModal;