import React, {useState} from "react";
import countriesCities from "./locations.json";
import {Select, MenuItem, FormControl, InputLabel, Button, Box} from '@mui/material';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

const CountryCitySelector = ({
                                 selectedCountry, selectedCity, selectedDate,
                                 handleCountryChange, handleCityChange, handleDateChange, handleGetSearchResults
                             }) => {
    const cities = countriesCities[selectedCountry] || [];
    const [differentParams, setDifferentParams] = useState(false);

    const toggleDiv = () => setDifferentParams(!differentParams);

    return (
        // <button className="toggle-button" onClick={toggleDiv}>
        //     {differentParams ? "Hide" : "Start"} New Search
        // </button>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400, margin: '0 auto'}}>

            <Button variant="contained" onClick={toggleDiv}>
                {differentParams ? "Hide" : "Start"} New Search
            </Button>
            {differentParams && (
                <FormControl fullWidth>
                    <InputLabel id="dropdown1-label">Departure Country</InputLabel>
                    <Select
                        labelId="dropdown1-label"
                        id="dropdown1"
                        value={selectedCountry}
                        label="Option 1"
                        onChange={handleCountryChange}
                    >
                        {Object.keys(countriesCities).map((country) => (
                            <MenuItem value={country}>
                                {country}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            {differentParams && (
                <FormControl fullWidth>
                    <InputLabel id="dropdown2-label">Departure City</InputLabel>
                    <Select
                        labelId="dropdown2-label"
                        id="dropdown2"
                        value={selectedCity}
                        label="Option 2"
                        onChange={handleCityChange}
                    >
                        {cities.map((city) => (
                            <MenuItem key={city} value={city}>
                                {city}
                            </MenuItem>
                        ))}

                    </Select>
                </FormControl>
            )}

            {differentParams && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Departure Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <FormControl fullWidth>{params.input}</FormControl>}
                    />
                </LocalizationProvider>
            )}
            {differentParams && (
                <Button variant="contained" onClick={handleGetSearchResults}>
                    Find Deals
                </Button>
            )}
        </Box>
    );
};

export default CountryCitySelector;